// 3rd Party
import { useEffect, useState } from 'react'
import Link from 'next/link'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

// Styles
import styleConfig from '@/styles/style-config'

// Components
import CTA from '@/components/CTA'
import { NextImage } from '@/components/Image/NextImage'
import ModuleContent2ImagesArticleSideBySide from '@/components/ModuleContent2Images/ModuleContent2ImagesArticleSideBySide'

// Contentful
import { removePx, initSlider } from '@/lib/splide-slider'
import { enableContent2ImagesPreview } from '@/lib/contentful/module'
import { addForwardSlash } from '@/lib/helper'

import { contentClick } from '@/lib/ga-events'

export default function ModuleContent2Images(props) {
  const { preview } = props
  const [item, setItem] = useState(props.fields || props)
  useEffect(async () => {
    if (preview) {
      const res = await enableContent2ImagesPreview(props, preview)
      setItem(res)
    }
  }, [])

  const sliderSelector = `splide-${item?.sys?.id || 1}`
  const [sliderBarWidth, setSliderBarWidth] = useState(50)

  const slideIndicator = (params) => {
    if (params === 0) {
      setSliderBarWidth(50)
    } else {
      params && setSliderBarWidth(100)
    }
  }

  useEffect(() => {
    if (item.layout == 'Default') {
      initSlider(`.${sliderSelector}`, {
        gap: '12px',
        perPage: 2,
        arrows: false,
        pagination: false,
        drag: false,
        breakpoints: {
          [removePx(styleConfig.theme.screens.sm) - 1]: {
            drag: true,
            perPage: 1,
            fixedWidth: '305px',
            perMove: 1,
          },
        },
      }).on('mounted move', (event) => {
        slideIndicator(event)
      })
    }
  }, [item])

  let WidthHeight = styleConfig.theme.screens.xs && 584
  WidthHeight = styleConfig.theme.screens.sm && 314
  WidthHeight = styleConfig.theme.screens.md && 344
  WidthHeight = styleConfig.theme.screens.lg && 479
  WidthHeight = styleConfig.theme.screens.xl && 634

  const ctaLeft1 = {
    id: item?.ctaLeft1?.id || null,
    label: item?.ctaLeft1?.label || item?.ctaRightText,
    openInNewTab: item?.ctaLeft1?.openInNewTab || false,
    openVideoPlayer: item?.ctaLeft1?.openVideoPlayer || false,
    url: item?.ctaLeft1?.url || item?.ctaRightUrl,
    bgColor: item?.ctaLeft1?.backgroundColor,
  }

  const ctaRight1 = {
    id: item?.ctaRight1?.id || null,
    label: item?.ctaRight1?.label || item?.ctaRightText,
    openInNewTab: item?.ctaRight1?.openInNewTab || false,
    openVideoPlayer: item?.ctaRight1?.openVideoPlayer || false,
    url: item?.ctaRight1?.url || item?.ctaRightUrl,
    bgColor: item?.ctaRight1?.backgroundColor,
  }

  const ctaLeft2 = {
    id: item?.ctaLeft2?.id || null,
    label: item?.ctaLeft2?.label || item?.ctaRightText,
    openInNewTab: item?.ctaLeft2?.openInNewTab || false,
    openVideoPlayer: item?.ctaLeft2?.openVideoPlayer || false,
    url: item?.ctaLeft2?.url || item?.ctaRightUrl,
    bgColor: item?.ctaLeft2?.backgroundColor,
  }

  const ctaRight2 = {
    id: item?.ctaRight2?.id || null,
    label: item?.ctaRight2?.label || item?.ctaRightText,
    openInNewTab: item?.ctaRight2?.openInNewTab || false,
    openVideoPlayer: item?.ctaRight2?.openVideoPlayer || false,
    url: item?.ctaRight2?.url || item?.ctaRightUrl,
    bgColor: item?.ctaRight2?.backgroundColor,
  }

  const ctaClassName = 'pl-4 pr-4 tracking-widest btn'

  return (
    <>
      {item?.layout == 'Article Side By Side' ? (
        <ModuleContent2ImagesArticleSideBySide
          item={item}
          ctaClassName={ctaClassName}
          ctaLeft1={ctaLeft1}
          ctaLeft2={ctaLeft2}
          ctaRight1={ctaRight1}
          ctaRight2={ctaRight2}
        />
      ) : (
        <>
          <div
            className={`module-content-2images splide ${sliderSelector} ml-[24px] lg:mx-[80px] ${
              item?.categoryName || item?.sys?.id == '2fWPrmKiowZFdZImjyvjkL'
                ? 'category-slider'
                : ''
            } ${
              !item.generalPaddingDisable ? 'pb-[60px] md:pb-[100px]' : ''
            } text-white md:min-h-[600px] gap-y-[40px] lg:gap-y-0
            ${item.stackOrSlide == 'Stack' && 'hidden lg:block'}`}
          >
            <div className="splide__track">
              <div className="splide__list">
                {item?.firstBackgroundLink ? (
                  <Link href={addForwardSlash(item?.firstBackgroundLink)}>
                    <a
                      className={`w-full lg:w-1/2 lg:order-1 lg:mr-2.5 text-black splide__slide`}
                      onClick={() => {
                        contentClick(ctaLeft1.label, addForwardSlash(item?.firstBackgroundLink))
                      }}
                    >
                      <div>
                        <div className="min-h-[420px] md:min-h-[600px] flex align-content__center items-end px-5 py-5 block-bg-gray relative h-full block-bg-gray bg-center bg-cover">
                          <div className="imageBackground">
                            <NextImage
                              src={item.backgroundImage}
                              alt={item.altTag}
                              layout="fill"
                              objectFit="cover"
                              objectPosition="center"
                              className="z-0"
                            />
                          </div>
                          <div className="z-10 w-full text-white">
                            <h1
                              className={`font-light text-white leading-tight text-xs uppercase tracking-widest`}
                            >
                              {item.eyebrow}
                            </h1>
                            <p className="my-3 font-bold leading-none tracking-widest text-white text-md">
                              {item.headline}
                            </p>
                            <div className="max-h-[192px] overflow-hidden md:max-h-unset info-block">
                              {item?.bodyText?.data
                                ? documentToReactComponents(item.bodyText)
                                : item.bodyText}
                            </div>
                            {(ctaLeft1.url || ctaRight1.url) && (
                              <div
                                className={`mt-6 button-group-wrap flex gap-2 justify-center md:justify-start`}
                              >
                                {ctaLeft1.url && ctaLeft1.label && (
                                  <CTA
                                    className={`${ctaClassName} btn-primary`}
                                    label={ctaLeft1.label}
                                    openInNewTab={ctaLeft1.openInNewTab}
                                    openVideoPlayer={ctaLeft1.openVideoPlayer}
                                    url={ctaLeft1.url}
                                    background={ctaLeft1.bgColor}
                                    moduleProps={props}
                                  />
                                )}
                                {ctaRight1.url && ctaRight1.label && (
                                  <CTA
                                    className={`${ctaClassName} btn-secondary`}
                                    label={ctaLeft1.label}
                                    openInNewTab={ctaLeft1.openInNewTab}
                                    openVideoPlayer={ctaLeft1.openVideoPlayer}
                                    url={ctaLeft1.url}
                                    background={ctaLeft1.bgColor}
                                    moduleProps={props}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </a>
                  </Link>
                ) : (
                  <div className={`w-full lg:w-1/2 lg:order-1 lg:mr-2.5 text-black splide__slide`}>
                    <div className=" min-h-[420px] md:min-h-[600px] flex align-content__center items-end px-5 py-5 block-bg-gray relative h-full block-bg-gray bg-center bg-cover">
                      <div className="imageBackground">
                        <NextImage
                          src={item.backgroundImage}
                          alt={item.altTag}
                          layout="fill"
                          objectFit="cover"
                          objectPosition="center"
                          className="z-0"
                        />
                      </div>
                      <div className="z-10 w-full text-white">
                        <h1
                          className={`font-light text-white leading-tight text-xs uppercase tracking-widest`}
                        >
                          {item.eyebrow}
                        </h1>
                        <p className="my-3 font-bold leading-none tracking-widest text-white text-md">
                          {item.headline}
                        </p>
                        <div className="max-h-[192px] overflow-hidden md:max-h-unset info-block">
                          {item?.bodyText?.data
                            ? documentToReactComponents(item.bodyText)
                            : item.bodyText}
                        </div>
                        {(ctaLeft1.url || ctaRight1.url) && (
                          <div
                            className={`mt-6 button-group-wrap flex gap-2 justify-center md:justify-start`}
                          >
                            {ctaLeft1.url && ctaLeft1.label && (
                              <CTA
                                className={`${ctaClassName} btn-primary`}
                                label={ctaLeft1.label}
                                openInNewTab={ctaLeft1.openInNewTab}
                                openVideoPlayer={ctaLeft1.openVideoPlayer}
                                url={ctaLeft1.url}
                                background={ctaLeft1.bgColor}
                                moduleProps={props}
                              />
                            )}
                            {ctaRight1.url && ctaRight1.label && (
                              <CTA
                                className={`${ctaClassName} btn-secondary`}
                                label={ctaLeft1.label}
                                openInNewTab={ctaLeft1.openInNewTab}
                                openVideoPlayer={ctaLeft1.openVideoPlayer}
                                url={ctaLeft1.url}
                                background={ctaLeft1.bgColor}
                                moduleProps={props}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {item?.secondBackgroundLink ? (
                  <Link href={addForwardSlash(item?.secondBackgroundLink)}>
                    <a
                      className={`w-full lg:w-1/2 lg:order-2 lg:ml-2.5 text-black splide__slide`}
                      onClick={() => {
                        contentClick(ctaLeft2.label, addForwardSlash(item?.secondBackgroundLink))
                      }}
                    >
                      <div>
                        <div className="min-h-[420px] md:min-h-[600px] flex align-content__center items-end px-5 py-5 block-bg-gray relative h-full block-bg-gray bg-center bg-cover">
                          <div className="imageBackground">
                            <NextImage
                              src={item.backgroundImage2}
                              layout="fill"
                              alt={item.altTag}
                              objectFit="cover"
                              objectPosition="center"
                              className="z-0 "
                            />
                          </div>
                          <div className="z-10 flex flex-col w-full text-white">
                            <h1
                              className={`font-light text-white leading-tight text-xs uppercase tracking-widest`}
                            >
                              {item.eyebrow2}
                            </h1>
                            <p className="my-3 font-bold leading-none tracking-widest text-white text-md">
                              {item.headline2}
                            </p>
                            <div className="max-h-[192px] overflow-hidden md:max-h-unset info-block">
                              {item?.bodyText2?.data
                                ? documentToReactComponents(item.bodyText2)
                                : item.bodyText2}
                            </div>
                            {(ctaLeft2.url || ctaRight2.url) && (
                              <div
                                className={`mt-6 button-group-wrap flex gap-2 justify-center md:justify-start`}
                              >
                                {ctaLeft2.url && ctaLeft2.label && (
                                  <CTA
                                    className={`${ctaClassName} btn-primary`}
                                    label={ctaLeft2.label}
                                    openInNewTab={ctaLeft2.openInNewTab}
                                    openVideoPlayer={ctaLeft2.openVideoPlayer}
                                    url={ctaLeft2.url}
                                    background={ctaLeft2.bgColor}
                                    moduleProps={props}
                                  />
                                )}
                                {ctaRight2.url && ctaRight2.label && (
                                  <CTA
                                    className={`${ctaClassName} btn-secondary`}
                                    label={ctaRight2.label}
                                    openInNewTab={ctaRight2.openInNewTab}
                                    openVideoPlayer={ctaRight2.openVideoPlayer}
                                    url={ctaRight2.url}
                                    background={ctaRight2.bgColor}
                                    moduleProps={props}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </a>
                  </Link>
                ) : (
                  <div className={`w-full lg:w-1/2 lg:order-2 lg:ml-2.5 text-black splide__slide`}>
                    <div className="relative flex align-content__center items-end h-full px-5 py-5 bg-center bg-cover block-bg-gray content-horizontal__image">
                      <div className="imageBackground">
                        <NextImage
                          src={item.backgroundImage2}
                          layout="fill"
                          alt={item.altTag}
                          objectFit="cover"
                          objectPosition="center"
                          className="z-0 "
                        />
                      </div>
                      <div className="z-10 flex flex-col w-full text-white">
                        <h1
                          className={`font-light text-white leading-tight text-xs uppercase tracking-widest`}
                        >
                          {item.eyebrow2}
                        </h1>
                        <p className="my-3 font-bold leading-none tracking-widest text-white text-md">
                          {item.headline2}
                        </p>
                        <div className="max-h-[192px] overflow-hidden md:max-h-unset info-block">
                          {item?.bodyText2?.data
                            ? documentToReactComponents(item.bodyText2)
                            : item.bodyText2}
                        </div>
                        {(ctaLeft2.url || ctaRight2.url) && (
                          <div
                            className={`mt-6 button-group-wrap flex gap-2 justify-center md:justify-start`}
                          >
                            {ctaLeft2.url && ctaLeft2.label && (
                              <CTA
                                className={`${ctaClassName} btn-primary`}
                                label={ctaLeft2.label}
                                openInNewTab={ctaLeft2.openInNewTab}
                                openVideoPlayer={ctaLeft2.openVideoPlayer}
                                url={ctaLeft2.url}
                                background={ctaLeft2.bgColor}
                                moduleProps={props}
                              />
                            )}
                            {ctaRight2.url && ctaRight2.label && (
                              <CTA
                                className={`${ctaClassName} btn-secondary`}
                                label={ctaRight2.label}
                                openInNewTab={ctaRight2.openInNewTab}
                                openVideoPlayer={ctaRight2.openVideoPlayer}
                                url={ctaRight2.url}
                                background={ctaRight2.bgColor}
                                moduleProps={props}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={`my-slider-progress bg-gray-200 mt-8 md:mt-0 lg:hidden`}>
              <div
                data-width={`${sliderBarWidth}%`}
                className={`my-slider-progress-bar bg-primary ${sliderSelector}-progress_bar`}
                style={{ width: `${sliderBarWidth}%` }}
              ></div>
            </div>
          </div>
          {/* Option just for mobile */}
          {item.stackOrSlide == 'Stack' && (
            <div className="content-2images__card lg:hidden mx-[24px]">
              {item?.firstBackgroundLink ? (
                <Link href={addForwardSlash(item?.firstBackgroundLink)}>
                  <a className={`w-full text-black mb-[24px]`}>
                    <div>
                      <div className="min-h-[420px] md:min-h-[600px] flex align-content__center items-end px-5 py-5 block-bg-gray relative h-full block-bg-gray bg-center bg-cover">
                        <div className="imageBackground">
                          <NextImage
                            src={item.backgroundImage}
                            alt={item.altTag}
                            layout="fill"
                            objectFit="cover"
                            objectPosition="center"
                            className="z-0"
                          />
                        </div>
                        <div className="z-10 w-full text-white">
                          <h1
                            className={`font-light text-white leading-tight text-xs uppercase tracking-widest`}
                          >
                            {item.eyebrow}
                          </h1>
                          <p className="my-3 font-bold leading-none tracking-widest text-white text-md">
                            {item.headline}
                          </p>
                          <div className="max-h-[192px] overflow-hidden md:max-h-unset info-block">
                            {item?.bodyText?.data
                              ? documentToReactComponents(item.bodyText)
                              : item.bodyText}
                          </div>
                          {(ctaLeft1.url || ctaRight1.url) && (
                            <div
                              className={`mt-6 button-group-wrap flex gap-2 justify-center md:justify-start`}
                            >
                              {ctaLeft1.url && ctaLeft1.label && (
                                <CTA
                                  className={`${ctaClassName} btn-primary`}
                                  label={ctaLeft1.label}
                                  openInNewTab={ctaLeft1.openInNewTab}
                                  openVideoPlayer={ctaLeft1.openVideoPlayer}
                                  url={ctaLeft1.url}
                                  background={ctaLeft1.bgColor}
                                  moduleProps={props}
                                />
                              )}
                              {ctaRight1.url && ctaRight1.label && (
                                <CTA
                                  className={`${ctaClassName} btn-secondary`}
                                  label={ctaLeft1.label}
                                  openInNewTab={ctaLeft1.openInNewTab}
                                  openVideoPlayer={ctaLeft1.openVideoPlayer}
                                  url={ctaLeft1.url}
                                  background={ctaLeft1.bgColor}
                                  moduleProps={props}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </a>
                </Link>
              ) : (
                <div className={`w-full text-black mb-[24px]`}>
                  <div className="min-h-[420px] md:min-h-[600px] flex align-content__center items-end px-5 py-5 block-bg-gray relative h-full block-bg-gray bg-center bg-cover">
                    <div className="imageBackground">
                      <NextImage
                        src={item.backgroundImage}
                        alt={item.altTag}
                        layout="fill"
                        objectFit="cover"
                        objectPosition="center"
                        className="z-0"
                      />
                    </div>
                    <div className="z-10 w-full text-white">
                      <h1
                        className={`font-light text-white leading-tight text-xs uppercase tracking-widest`}
                      >
                        {item.eyebrow}
                      </h1>
                      <p className="my-3 font-bold leading-none tracking-widest text-white text-md">
                        {item.headline}
                      </p>
                      <div className="max-h-[192px] overflow-hidden md:max-h-unset info-block">
                        {item?.bodyText?.data
                          ? documentToReactComponents(item.bodyText)
                          : item.bodyText}
                      </div>
                      {(ctaLeft1.url || ctaRight1.url) && (
                        <div
                          className={`mt-6 button-group-wrap flex gap-2 justify-center md:justify-start`}
                        >
                          {ctaLeft1.url && ctaLeft1.label && (
                            <CTA
                              className={`${ctaClassName} btn-primary`}
                              label={ctaLeft1.label}
                              openInNewTab={ctaLeft1.openInNewTab}
                              openVideoPlayer={ctaLeft1.openVideoPlayer}
                              url={ctaLeft1.url}
                              background={ctaLeft1.bgColor}
                              moduleProps={props}
                            />
                          )}
                          {ctaRight1.url && ctaRight1.label && (
                            <CTA
                              className={`${ctaClassName} btn-secondary`}
                              label={ctaLeft1.label}
                              openInNewTab={ctaLeft1.openInNewTab}
                              openVideoPlayer={ctaLeft1.openVideoPlayer}
                              url={ctaLeft1.url}
                              background={ctaLeft1.bgColor}
                              moduleProps={props}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {item?.secondBackgroundLink ? (
                <Link href={addForwardSlash(item?.secondBackgroundLink)}>
                  <a className={`w-full text-black mb-[24px]`}>
                    <div>
                      <div className="min-h-[420px] md:min-h-[600px] flex align-content__center items-end px-5 py-5 block-bg-gray relative h-full block-bg-gray bg-center bg-cover">
                        <div className="imageBackground">
                          <NextImage
                            src={item.backgroundImage2}
                            layout="fill"
                            alt={item.altTag}
                            objectFit="cover"
                            objectPosition="center"
                            className="z-0 "
                          />
                        </div>
                        <div className="z-10 flex flex-col w-full text-white">
                          <h1
                            className={`font-light text-white leading-tight text-xs uppercase tracking-widest`}
                          >
                            {item.eyebrow2}
                          </h1>
                          <p className="my-3 font-bold leading-none tracking-widest text-white text-md">
                            {item.headline2}
                          </p>
                          <div className="max-h-[192px] overflow-hidden md:max-h-unset info-block">
                            {item?.bodyText2?.data
                              ? documentToReactComponents(item.bodyText2)
                              : item.bodyText2}
                          </div>
                          {(ctaLeft2.url || ctaRight2.url) && (
                            <div
                              className={`mt-6 button-group-wrap flex gap-2 justify-center md:justify-start`}
                            >
                              {ctaLeft2.url && ctaLeft2.label && (
                                <CTA
                                  className={`${ctaClassName} btn-primary`}
                                  label={ctaLeft2.label}
                                  openInNewTab={ctaLeft2.openInNewTab}
                                  openVideoPlayer={ctaLeft2.openVideoPlayer}
                                  url={ctaLeft2.url}
                                  background={ctaLeft2.bgColor}
                                  moduleProps={props}
                                />
                              )}
                              {ctaRight2.url && ctaRight2.label && (
                                <CTA
                                  className={`${ctaClassName} btn-secondary`}
                                  label={ctaRight2.label}
                                  openInNewTab={ctaRight2.openInNewTab}
                                  openVideoPlayer={ctaRight2.openVideoPlayer}
                                  url={ctaRight2.url}
                                  background={ctaRight2.bgColor}
                                  moduleProps={props}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </a>
                </Link>
              ) : (
                <div className={`w-full text-black mb-[24px]`}>
                  <div className="min-h-[420px] md:min-h-[600px] relative flex align-content__center items-end h-full px-5 py-5 bg-center bg-cover block-bg-gray ">
                    <div className="imageBackground">
                      <NextImage
                        src={item.backgroundImage2}
                        layout="fill"
                        alt={item.altTag}
                        objectFit="cover"
                        objectPosition="center"
                        className="z-0 "
                      />
                    </div>
                    <div className="z-10 flex flex-col w-full text-white">
                      <h1
                        className={`font-light text-white leading-tight text-xs uppercase tracking-widest`}
                      >
                        {item.eyebrow2}
                      </h1>
                      <p className="my-3 font-bold leading-none tracking-widest text-white text-md">
                        {item.headline2}
                      </p>
                      <div className="max-h-[192px] overflow-hidden md:max-h-unset info-block">
                        {item?.bodyText2?.data
                          ? documentToReactComponents(item.bodyText2)
                          : item.bodyText2}
                      </div>
                      {(ctaLeft2.url || ctaRight2.url) && (
                        <div
                          className={`mt-6 button-group-wrap flex gap-2 justify-center md:justify-start`}
                        >
                          {ctaLeft2.url && ctaLeft2.label && (
                            <CTA
                              className={`${ctaClassName} btn-primary`}
                              label={ctaLeft2.label}
                              openInNewTab={ctaLeft2.openInNewTab}
                              openVideoPlayer={ctaLeft2.openVideoPlayer}
                              url={ctaLeft2.url}
                              background={ctaLeft2.bgColor}
                              moduleProps={props}
                            />
                          )}
                          {ctaRight2.url && ctaRight2.label && (
                            <CTA
                              className={`${ctaClassName} btn-secondary`}
                              label={ctaRight2.label}
                              openInNewTab={ctaRight2.openInNewTab}
                              openVideoPlayer={ctaRight2.openVideoPlayer}
                              url={ctaRight2.url}
                              background={ctaRight2.bgColor}
                              moduleProps={props}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
      <style jsx>
        {`
          @media (max-width: 767px) {
            .align-content__center {
              align-items: center;
              justify-content: center;
              text-align: center;
            }
          }
          @media (min-width: 768px) {
            .align-content__center {
              align-items: left;
              text-align: left;
            }
          }
          @media (max-width: 767px) {
            .below-desktop-content {
              max-width: 100%;
              margin: 0 auto;
              margin-left: 24px;
            }
          }
          .content-horizontal__image {
            min-height: 150px;
          }
          @media (max-width: 768px) {
            .category-block {
              display: flex;
              flex-direction: row;
              overflow: auto;
              min-height: auto;
              padding-bottom: 0;
              margin: 0;
              margin-top: 20px;
              margin-bottom: 40px;
              width: 100%;
              max-width: none;
              padding-left: 24px;
            }
            .category-block .block {
              min-width: 280px;
              min-height: 420px;
              margin-right: 12px;
            }
          }
          .info-block {
            text-overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .imageBackground::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: ${item.overlayType === 'Dark'
              ? `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`
              : `linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))`};
          }
        `}
      </style>
    </>
  )
}
