import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { NextImage } from '@/components/Image/NextImage'
import CTA from '@/components/CTA'

const ModuleContent2ImagesArticleSideBySide = (props) => {
  return (
    <>
      <div className="module-content-2images-article-size-by-side custom_splide_width mx-auto">
        <div className="grid grid-row-2 md:grid-cols-2 gap-5">
          <div className="w-full min-h-[420px] md:min-h-[560px] px-6 py-7 relative flex items-end">
            <div className="absolute inset-0">
              <NextImage
                src={props.item?.backgroundImage}
                alt={item.altTag}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                className="z-0"
              />
              <div className="absolute inset-0 bg-black opacity-20"></div>
              <div className="absolute inset-0 overlay"></div>
            </div>
            <div className="text-white relative">
              <p className="text-2x uppercase mb-[5px] opacity-75"> {props.item.eyebrow}</p>
              <h3 className="text-md uppercase leading-[28px] tracking-[0.08em] font-bold mb-2.5">
                {' '}
                {props.item.headline}
              </h3>
              <div className="leading-[26px] tracking-[0.02em] mb-5 md:mb-6 md:max-w-[470px]">
                {props.item?.bodyText?.data
                  ? documentToReactComponents(props.item.bodyText)
                  : props.item.bodyText}
              </div>
              {(props.ctaLeft1.url || ctaRight1.url) && (
                <div className={`button-group-wrap flex gap-4`}>
                  {props.ctaLeft1.url && props.ctaLeft1.label && (
                    <CTA
                      className={`${props.ctaClassName} btn-primary rounded-[2px] min-w-[125px]`}
                      label={props.ctaLeft1.label}
                      openInNewTab={props.ctaLeft1.openInNewTab}
                      openVideoPlayer={props.ctaLeft1.openVideoPlayer}
                      url={props.ctaLeft1.url}
                      background={props.ctaLeft1.bgColor}
                      moduleProps={props}
                    />
                  )}
                  {props.ctaRight1.url && props.ctaRight1.label && (
                    <CTA
                      className={`${props.ctaClassName} btn-secondary rounded-[2px] min-w-[125px]`}
                      label={props.ctaLeft1.label}
                      openInNewTab={props.ctaLeft1.openInNewTab}
                      openVideoPlayer={props.ctaLeft1.openVideoPlayer}
                      url={props.ctaLeft1.url}
                      background={props.ctaLeft1.bgColor}
                      moduleProps={props}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="w-full min-h-[420px] md:min-h-[560px] px-6 py-7 relative flex items-end">
            <div className="absolute inset-0">
              <NextImage
                src={props.item?.backgroundImage2}
                alt={item.altTag}
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                className="z-0"
              />
              <div className="absolute inset-0 bg-black opacity-20"></div>
              <div className="absolute inset-0 overlay"></div>
            </div>
            <div className="text-white relative">
              <p className="text-2x uppercase mb-[5px] opacity-75"> {props.item.eyebrow2}</p>
              <h3 className="text-md uppercase leading-[28px] tracking-[0.08em] font-bold mb-2.5">
                {' '}
                {props.item.headline2}
              </h3>
              <div className="leading-[26px] tracking-[0.02em] mb-5 md:mb-6 md:max-w-[470px]">
                {props.item?.bodyText2?.data
                  ? documentToReactComponents(props.item.bodyText2)
                  : props.item.bodyText2}
              </div>
              {(props.ctaLeft2.url || ctaRight2.url) && (
                <div className={`button-group-wrap flex gap-4`}>
                  {props.ctaLeft2.url && props.ctaLeft2.label && (
                    <CTA
                      className={`${props.ctaClassName} btn-primary rounded-[2px] min-w-[125px]`}
                      label={props.ctaLeft2.label}
                      openInNewTab={props.ctaLeft2.openInNewTab}
                      openVideoPlayer={props.ctaLeft2.openVideoPlayer}
                      url={props.ctaLeft2.url}
                      background={props.ctaLeft2.bgColor}
                      moduleProps={props}
                    />
                  )}
                  {props.ctaRight2.url && props.ctaRight2.label && (
                    <CTA
                      className={`${props.ctaClassName} btn-secondary rounded-[2px] min-w-[125px]`}
                      label={props.ctaLeft2.label}
                      openInNewTab={props.ctaLeft2.openInNewTab}
                      openVideoPlayer={props.ctaLeft2.openVideoPlayer}
                      url={props.ctaLeft2.url}
                      background={props.ctaLeft2.bgColor}
                      moduleProps={props}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .overlay {
          background: linear-gradient(
            360deg,
            #000000 1.29%,
            rgba(0, 0, 0, 0.3) 55.19%,
            rgba(0, 0, 0, 0) 100%
          );
        }
      `}</style>
    </>
  )
}

export default ModuleContent2ImagesArticleSideBySide
