import Splide from '@splidejs/splide'
import '@splidejs/splide/dist/css/splide.min.css'

// initialize slider
export const initSlider = (selector, options) => {
  if (typeof window !== 'undefined') {
    const slider = new Splide(selector, options)

    slider.mount()

    return slider
  }

  return {}
}

// removes px text from string. usecase tailwind screen config
export const removePx = (string) => {
  return string.replace('px', '')
}

export const initSliderSplide = (selector, options) => {
  if (typeof window !== 'undefined') {
    const slider = new Splide(selector, options)
    const bar = slider.root.querySelector('.my-slider-progress-bar')
    slider.mount()
    slider.on('mounted move', () => {
      const end = slider.Components.Controller.edgeIndex
      let currentindex = slider.Components.Controller.nextIndex
      bar != null ? (bar.style.width = String((100 * slider.index) / end) + '%') : null
    })
    return slider
  }

  return {}
}

export const initSliderwithoutProgressBar = (selector, options) => {
  if (typeof window !== 'undefined') {
    const Grid = require('@splidejs/splide-extension-grid').default
    const slider = new Splide(selector, options)
    slider.mount({ Grid })

    return slider
  }

  return {}
}
